<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <KTCodePreview>
      <!-- CONTENT -->
      <template v-slot:preview>
        <!-- FILTER CONTENT -->
        <b-row class="mb-5">
          <b-col md="2">
            <b-form>
              <vue-autosuggest
                v-model="searchStock"
                :suggestions="filteredOptions"
                @selected="onSelected"
                :limit="10"
                @input="onInputChangeStore"
                :input-props="inputPropStore"
              >
                <div
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  {{ suggestion.item.name }}
                </div></vue-autosuggest
              >
            </b-form>
          </b-col>
          <b-col md="2">
            <b-input-group>
              <date-picker
                placeholder="Từ"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.fromDate"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <b-input-group>
              <date-picker
                placeholder="Đến"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.toDate"
              ></date-picker>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <b-form>
              <vue-autosuggest
                class="border-radius-none"
                aria-describedby="input-store-live-feedback"
                :suggestions="filteredOptionsProvider"
                @selected="onSelectedProvider"
                :limit="200"
                @input="onInputChangeProvider"
                :input-props="{
                  id: 'autosuggest__input',
                  placeholder: 'Chọn nhà cung cấp',
                  style: 'border-radius:0px!important',
                }"
                v-model="searchProvider"
              >
                <div
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <div style="{ display: 'flex', color: 'navyblue'}">
                    {{ suggestion.item.suggestionName }}
                  </div>
                </div>
              </vue-autosuggest>
            </b-form>
          </b-col>
          <b-col md="2">
            <b-input
              placeholder="Mã XNK"
              v-model="apiParams.contractCode"
              append-icon="search"
              single-line
              hide-details
              size="sm"
            />
          </b-col>
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="fetchMainData()"
              >Lọc
            </b-button>
          </b-col>
        </b-row>
        <!-- LIST DATA -->
        <b-row>
          <b-col md="12" lg="12" sm="12">
            <b-table
              :items="mainList.dataset"
              :fields="tableFields"
              :busy="onLoadingList"
              class="table-bordered table-hover"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10" />
              </template>
              <!-- Format id column -->
              <template v-slot:cell(index)="row">
                {{ row.index + 1 + getOffsetItem }}
              </template>
              <template v-slot:cell(code)="row">
                <router-link
                  :to="{
                    name: 'info-purchase-orders',
                    query: { id: row.item.code },
                  }"
                  class="link"
                  >{{ row.item.code }}
                </router-link>
              </template>
              <template v-slot:cell(totalAmount)="row">
                {{ convertPrice(row.item.totalAmount) }}
              </template>
              <template v-slot:cell(disscountAmount)="row">
                {{ convertPrice(row.item.disscountAmount) }}
              </template>
              <template v-slot:cell(payAmount)="row">
                {{ convertPrice(row.item.payAmount) }}
              </template>
              <template v-slot:cell(paidAmount)="row">
                {{ convertPrice(row.item.paidAmount) }}
              </template>
              <template v-slot:cell(debtAmount)="row">
                {{ convertPrice(row.item.debtAmount) }}
              </template>
              <template v-slot:cell(payDate)="row">
                <span style="display: block">{{ row.item.payDate }}</span>
                <span
                  v-text="row.item.debtStatusName"
                  class="label font-weight-bold label-lg label-inline"
                  v-bind:class="{
                    'label-light-warning': row.item.debtStatus === 3,
                    'label-light-success': row.item.debtStatus === 1,
                    'label-light-danger': row.item.debtStatus === 2,
                  }"
                  style="width: max-content"
                ></span>
              </template>
              <template
                v-slot:cell(actions)="row"
                v-if="isShow('TRANSACTION_INSERT')"
              >
                <div
                  class="d-flex justify-content-center"
                  v-if="row.item.debtAmount > 0"
                >
                  <b-dropdown size="sm" id="dropdown-left" right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item>
                      <router-link
                        :to="{
                          name: 'transaction',
                          params: {
                            mainModelProp: {
                              contactType: '2',
                              reconciliationDocType: '1',
                              reconciliationDocId: row.item.code,
                              type: '2',
                              contactId: row.item.providerId,
                              contactName: row.item.providerName,
                              amount: row.item.debtAmount,
                              note: getNoteForPay(row.item),
                            },
                            mode: 'cash',
                            action: 'add',
                            id: 'new',
                          },
                        }"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="far fa-money-bill-alt"
                          ></i>
                          &nbsp; Tạo phiếu chi
                        </span>
                      </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <router-link
                        :to="{
                          name: 'transaction',
                          params: {
                            mainModelProp: {
                              contactType: '2',
                              reconciliationDocType: '1',
                              reconciliationDocId: row.item.code,
                              type: '4',
                              contactId: row.item.providerId,
                              contactName: row.item.providerName,
                              amount: row.item.debtAmount,
                              note: getNoteForPay(row.item),
                            },
                            mode: 'bank',
                            action: 'add',
                            id: 'new',
                          },
                        }"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="fas fa-money-check-alt"
                          ></i>
                          &nbsp; Tạo phiếu báo nợ
                        </span>
                      </router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
              <template slot="bottom-row">
                <td colspan="3" class="text-right font-weight-bolder">
                  <span>Tổng</span>
                </td>
                <td class="text-right font-weight-bolder">
                  <span>{{ convertPrice(sumAmount('totalAmount')) }}</span>
                </td>
                <td class="text-right font-weight-bolder">
                  <span>{{ convertPrice(sumAmount('paidAmount')) }}</span>
                </td>
                <td class="text-right font-weight-bolder">
                  <span>{{ convertPrice(sumAmount('debtAmount')) }}</span>
                </td>
                <td></td>
                <td v-if="isShow('TRANSACTION_INSERT')"></td>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- PAGINATION -->
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ mainList.total }}
            </p>
          </b-col>
          <b-col lg="9" md="9" sm="12">
            <b-pagination-nav
              class="customPagination"
              v-show="mainList.pages >= 2"
              :link-gen="linkGen"
              :number-of-pages="mainList.pages"
              use-router
              @change="fetchMainData(pageInfo)"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { getToastInstance } from './../../../utils/toastHelper';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  // Data of view.
  data() {
    return {
      title: 'Hạn thanh toán nhà cung cấp',
      listStores: [{ value: '', text: 'Tất cả cửa hàng' }],
      listProviders: [{ value: '', text: 'Tất cả nhà cung cấp' }],
      optionPaidStatus: [
        { value: '', text: 'Công nợ trả góp' },
        { value: 'true', text: 'Hết nợ' },
        { value: 'false', text: 'Còn nợ' },
      ],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        limit: 10,
        storeId: '',
        fromDate: null,
        toDate: null,
        contactId: '',
        contractCode: null,
      },
      linkGen: (pageNum) => {
        this.apiParams.page = pageNum;
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Số phiếu',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
        },
        {
          key: 'providerName',
          label: 'Nhà cung cấp',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: 'calc(30% - 100px)',
          },
        },
        {
          key: 'totalAmount',
          label: 'Tổng tiền hóa đơn',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '14%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'paidAmount',
          label: 'Đã thanh toán',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '14%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'debtAmount',
          label: 'Còn nợ',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '14%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'payDate',
          label: 'Hạn thanh toán',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '13%',
          },
          tdClass: 'text-center',
        },
        {
          key: checkPermissions('TRANSACTION_INSERT') ? 'actions' : '',
          label: '',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
        },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStock: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      providerSelected: null,
      inputPropStore: {
        id: 'autosuggest__input',
        placeholder: 'Nhập cửa hàng',
        disabled: false,
      },
    };
  },
  // Component defination.
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    datePicker,
  },
  // Mounted view.
  mounted() {
    this.loadBreadcum();
  },
  created() {
    this.loadBreadcum();
    this.fetchStore();
    this.fetchProviders();
    this.fetchStoreByUser();
  },
  // Created view.
  methods: {
    ...getToastInstance(),
    payBank: function (item) {
      this.$router.push({
        path: '/accounting/transactions/bank/add/new',
        params: {
          mainModelProp: {
            contactType: '3',
            reconciliationDocType: '1',
            reconciliationDocId: item.billNumber,
            type: '3',
            contactId: item.installMoneyAccountId,
            contactName: item.installmentName,
            note: this.getNoteForPay(item),
          },
        },
      });
    },
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      this.apiParams.page = page;
      if (this.searchStock.trim() === '') {
        this.apiParams.storeId = null;
      }
      if (this.searchProvider.trim() === '') {
        this.providerSelected = null;
      }
      this.apiParams.contactId = this.providerSelected;
      // Init request header.
      this.onLoadingList = true;
      ApiService.query('po-debts', {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          console.log(respData);
          this.mainList.total = respData.totalRow;
          this.mainList.pages = respData.totalPage;
          this.mainList.dataset = [];
          this.mainList.dataset = respData.data.map((item, index) => {
            return {
              index: index + 1,
              ...item,
            };
          });
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchStore: async function () {
      ApiService.get('/stores/getStores').then((response) => {
        const data = {
          id: '',
          name: 'Tất cả cửa hàng',
          shortName: 'Tat ca cua hang',
        };
        this.optionsStore[0].data.push(data);
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
        });
        this.filteredOptions = [{ data: this.optionsStore[0].data }];
      });
    },
    fetchProviders() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.listProviders.map((element) => {
          let provider = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsProvider[0].data.push(provider);
        });
        let firstProvider = {
          id: null,
          name: 'Tất cả NCC',
          suggestionName: 'Tất cả NCC',
        };
        this.optionsProvider[0].data.unshift(firstProvider);
        let tmp = [{ data: this.optionsProvider[0].data }];
        this.filteredOptionsProvider = tmp;
      });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.providerSelected = option.item.id;
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsProvider = [
        {
          data: filteredData,
        },
      ];
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    getNoteForPay: function (item) {
      let note = `Thanh toán nhập hàng cho nhà cung cấp\n`;
      note += `Mã NK: ${item.code}`;
      return note;
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.apiParams.storeId = option.item.id;
      //this.onFilter();
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStock = stores[0].name;
            this.apiParams.storeId = stores[0].id;
            this.inputPropStore.disabled = true;
          } else {
            this.optionsStore[0].data = [];
            const data = {
              id: '',
              name: 'Tất cả cửa hàng',
              shortName: 'Tat ca cua hang',
            };
            this.optionsStore[0].data.push(data);
            stores.map((element) => {
              this.optionsStore[0].data.push(element);
            });
            this.filteredOptions = [{ data: this.optionsStore[0].data }];
            this.searchStock = data.name;
            this.apiParams.storeId = data.id;
          }
          this.fetchMainData();
        }
      });
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    getOffsetItem() {
      return (this.apiParams.page - 1) * this.apiParams.limit;
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>

<style>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
.link {
  color: unset;
}
.link:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
